<template>
    <div>
        <Table
            ref="table"
            :isLoading="isLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isPagination="true"
            :isFocusRow="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel', 'zooming']"
            :newToolKeys="newToolKeys"
            v-model:sortDefault="sortValue"
            :isFixedHeader="true"
        >
            <!-- 表格工具 -->
            <template v-slot:reminder>
                <div @click="showToolsModal('reminder')">
                    <div class="icon reminder-setting-icon" />
                    <div>通知設定</div>
                </div>
            </template>
            <template v-slot:excelEditing>
                <div @click="showToolsModal('excelEditing')">
                    <div class="icon edit-list-icon" />
                    <div>產險批次編輯</div>
                </div>
            </template>

            <!-- 手機版備註 -->
            <template v-if="isShowDueDateTips" v-slot:mobile-header>
                <p v-for="(tip, index) in headerTips.mobile" :key="index">
                    ・<span>{{ tip }}</span>
                </p></template
            >
            <!-- 表格 -->
            <template v-slot:propertyDueStatus="{ isHeader, row }">
                <div v-if="isHeader" />
                <div
                    v-if="!isHeader"
                    class="expire"
                    :class="{
                        'will-expire-icon': row.propertyDueStatus === 2,
                        'expired-icon': row.propertyDueStatus === 3
                    }"
                />
            </template>

            <template v-slot:insDetailLink="{ isHeader, row }">
                <router-link
                    v-if="!isHeader"
                    :to="{
                        name: 'TiaInsuranceDetail',
                        params: {
                            id: row.mainCode,
                            manCode: this.payload.manCodes[0]
                        }
                    }"
                    :class="{ 'detail-link-icon': !$isMobile() }"
                >
                    <Button
                        v-if="$isMobile()"
                        buttonSize="small"
                        iconClass="detail-link-icon"
                        buttonWord="明細"
                        class="mobile-button"
                    />
                </router-link>
            </template>

            <template v-slot:supplierInfo="{ isHeader, row }">
                <div
                    v-if="!isHeader && !$isMobile()"
                    :class="{
                        'supplier-info-icon': hasSupplierData(row),
                        disabled: !hasSupplierData(row)
                    }"
                    @click.stop="
                        showSupplierInfoModal(
                            row.mainCode,
                            row.fromSupplierDataStatus
                        )
                    "
                >
                    {{ hasSupplierData(row) ? '' : '無資訊' }}
                </div>
                <div
                    v-else-if="!isHeader && $isMobile()"
                    :class="{
                        disabled: !hasSupplierData(row)
                    }"
                    @click.stop="
                        showSupplierInfoModal(
                            row.mainCode,
                            row.fromSupplierDataStatus
                        )
                    "
                >
                    <Button
                        v-if="$isMobile()"
                        buttonSize="small"
                        iconClass="supplier-info-icon"
                        :buttonWord="
                            hasSupplierData(row) ? '最新訊息' : '無訊息'
                        "
                        class="mobile-button"
                    />
                </div>
            </template>
            <template v-slot:notice="{ isHeader, row }">
                <div
                    v-if="!isHeader && getNoticeCount(row) > 0"
                    class="has-event"
                    :class="{
                        'notice-icon': !$isMobile()
                    }"
                    @click.stop="showNoticeModal(row)"
                >
                    <div class="notice-count has-event">
                        {{ getShowNoticeCount(row) }}
                    </div>
                    <Button
                        v-if="$isMobile()"
                        :buttonWord="'保單資訊'"
                        :iconClass="'notice-icon'"
                        :buttonSize="'small'"
                        class="mobile-button"
                    />
                </div>
                <div
                    v-else-if="!isHeader && getNoticeCount(row) === 0"
                    class="has-event"
                    :class="{
                        'notice-icon': !$isMobile()
                    }"
                    @click.stop="showNoticeModal(row)"
                >
                    <Button
                        v-if="$isMobile()"
                        :buttonWord="'保單資訊'"
                        :iconClass="'notice-icon'"
                        :buttonSize="'small'"
                        class="mobile-button"
                    />
                </div>
            </template>
            <template v-slot:payer="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        class="customer"
                        :class="{ 'no-data': !row.payer }"
                        @click.stop="openCustomer(row.payerCode, row.payer)"
                    >
                        {{ row.payer ?? '-' }}
                    </a>
                </template>
            </template>

            <template v-slot:insured="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        class="customer"
                        :class="{ 'no-data': !row.insured }"
                        @click.stop="openCustomer(row.insuredCode, row.insured)"
                    >
                        {{ row.insured ?? '-' }}
                    </a>
                </template>
            </template>

            <template v-slot:solicitorOri="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    v-html="$cutSolicitorOriByRules(row.solicitorOri, 'device')"
                />
            </template>
        </Table>
    </div>
    <div v-if="isShowReminderSettingModal">
        <InsuranceReminderSettingModal
            v-model="isShowReminderSettingModal"
            v-bind="$attrs"
        />
    </div>

    <InsuranceExcelEditingModal
        :dataList="listData"
        v-model="isShowExcelEditingModal"
        v-bind="$attrs"
    />
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { insuranceColumns } from '@/assets/javascripts/tia/setting'
import InsuranceReminderSettingModal from '@/containers/tia/InsuranceReminderSettingModal.vue'
import InsuranceExcelEditingModal from '@/containers/tia/InsuranceExcelEditingModal.vue'
import Button from '../../components/Button.vue'

export default {
    name: 'insuranceTable',
    components: {
        Table,
        InsuranceReminderSettingModal,
        InsuranceExcelEditingModal,
        Button
    },
    props: {
        bgName: {
            type: String,
            default: ''
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        eventList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    emits: ['getinsuranceid', 'showsuppliersyncmodal'],
    methods: {
        getShowNoticeCount: function (row) {
            const unreadCount = this.getNoticeCount(row)
            return unreadCount > 9 ? '9+' : unreadCount // >=10個通知氣泡會跑版
        },
        getNoticeCount: function (row) {
            const checkedCount = this.eventList[row.mainCode] || 0 // 勾完通知的暫存'
            const detailCheckCount =
                this.$store.state.detailCheckedCount[row.mainCode] ?? 0
            const unreadCount =
                row.hasServiceEvent - checkedCount - detailCheckCount
            return unreadCount
        },
        showNoticeModal: function (row) {
            this.$setGaEvent('showNoticeModal', 'click-Icon')
            this.$emit('getinsuranceid', row)
        },
        showSupplierInfoModal: function (val, status) {
            if (status === null) return
            this.$emit('showsuppliersyncmodal', val)
            this.$setGaEvent('showSupplierSyncModal', 'click-Icon') //GA
        },
        openCustomer: function (code, name) {
            if (!code) return
            this.$setGaEvent('goSearchCustomer', 'click-Link')

            const searchPayload = { ...this.payload }
            searchPayload.customerCode = code
            searchPayload.customerName = name
            /* 刪除客戶查詢 payload 不需要的部分 */
            delete searchPayload.receiveDateStart
            delete searchPayload.receiveDateEnd
            this.$crossPageFilter('TiaCustomer', searchPayload)
        },
        hasSupplierData: function (row) {
            return row.fromSupplierDataStatus !== null
        },
        syncEditedColumns: function (row) {
            /* 同步被編輯欄位 */
            const editedList = this?.$store.state?.detailEditedList || {}
            const keys = Object.keys(editedList)
            const remindBeforeDays = this.$attrs.remindBeforeDays
            if (keys.length > 0) {
                keys.forEach(function (key) {
                    if (row.mainCode === key) {
                        row.vehicleNumber = editedList[key]?.vehicleNumber
                        row.propertyDueDate =
                            editedList[key]?.propertyDueDate || null
                        // 到期日狀態標誌變動
                        if (row.propertyDueDate) {
                            const newDueDate = new Date(row.propertyDueDate)
                            const today = new Date()
                            const isExpired = today >= newDueDate
                            const dateGap = parseInt(
                                Math.abs(newDueDate - today) /
                                    1000 /
                                    60 /
                                    60 /
                                    24
                            )
                            if (remindBeforeDays < dateGap && !isExpired)
                                row.propertyDueStatus = 1
                            else if (remindBeforeDays > dateGap && !isExpired)
                                row.propertyDueStatus = 2
                            else if (isExpired) row.propertyDueStatus = 3
                        } else {
                            row.propertyDueStatus = null
                        }
                    }
                })
            } else return
        },
        transformData: function (rows) {
            return rows.map((row) => {
                this.syncEditedColumns(row)

                if (row?.propertyDueStatus === 1)
                    row.propertyDueStatusTrans = '未到期'
                else if (row?.propertyDueStatus === 2)
                    row.propertyDueStatusTrans = `將到期${
                        this.$attrs.remindBeforeDays
                            ? `(${this.$attrs.remindBeforeDays}天內)`
                            : ''
                    }
`
                else if (row?.propertyDueStatus === 3)
                    row.propertyDueStatusTrans = '已到期'
                else row.propertyDueStatusTrans = '無到期日'

                if (
                    !row?.propertyDueDate &&
                    !row?.jobTypeText?.includes('產險')
                ) {
                    row.propertyDueDateTrans = '無到期日(非產險)'
                } else if (
                    !row?.propertyDueDate &&
                    row?.jobTypeText?.includes('產險')
                ) {
                    row.propertyDueDateTrans = '未填寫到期日(產險)'
                } else if (row?.propertyDueDate) {
                    row.propertyDueDateTrans = row.propertyDueDate
                }

                if (row.jobTypeText === '壽險') row['jobCode'] = 'life'
                else if (row.jobTypeText === '產險') row['jobCode'] = 'property'
                else if (row.jobTypeText === '團險及旅平險') {
                    row['jobCode'] = 'group'
                    row.jobTypeText = '團/旅平險'
                }

                if (row.insStatus === '通知') {
                    if (row.isVoid === true) row.insStatus = '註銷'
                    else if (row.isAudit === true) row.insStatus = '已審'
                    else row.insStatus = '未審'
                }
                if (row.insStatus === '未審') row['statusCode'] = 'process'
                if (row.insStatus === '已審') row['statusCode'] = 'audit'
                if (row.insStatus === '註銷') row['statusCode'] = 'void'
                row.solicitorOri = row?.solicitorOri || '-'
                row.rfyp = {
                    value: row?.rfyp,
                    unit: row?.rfypUnit
                }

                row.supplierInfo =
                    row?.fromSupplierDataStatus === null
                        ? '無資訊'
                        : '有最新資訊'

                row.notice = this.getNoticeCount(row) > 0 ? '有通知' : '無通知'

                return row
            })
        },
        showToolsModal: function (toolKey) {
            if (this.isLoading) return
            if (toolKey === 'reminder') {
                this.isShowReminderSettingModal = true
            }
            if (toolKey === 'excelEditing') {
                this.isShowExcelEditingModal = true
            }

            this.$setGaEvent(`${toolKey}ToolOpen`, 'click-Button')
        }
    },
    computed: {
        insEventList: function () {
            let dataList = _.cloneDeep(this.listData)
            const doneList = {}
            dataList.forEach(function (data) {
                if (data.hasServiceEvent > 0)
                    doneList[data.code] = data.hasServiceEvent
            })
            return doneList
        },
        dummyColum: function () {
            const obj = {}
            Object.keys(this.tableColumns).forEach((col) => {
                obj[col] = ' '
            })
            return obj
        },
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        fileName: function () {
            const Today = new Date()
            const year = Today.getFullYear()
            const month = Today.getMonth() + 1
            const day = Today.getDate()
            const account = '_' + this.filterLog?.manCodes?.label

            return `${year}${month > 9 ? month : '0' + month}${
                day > 9 ? day : '0' + day
            }${account}_保單清單`
        },
        excelConfig: function () {
            return {
                fileName: this.fileName,
                filters: this.filterLog
            }
        },
        headerTips: function () {
            return {
                mobile: [
                    '「車牌號碼」欄位內容可至「明細」中修改',
                    '「產險到期日」系統將自動為您預設到期日為生效日往後推一年，您亦可至「明細」中修改'
                ]
            }
        },
        tableColumns: {
            get: function () {
                if (!this.$hasPermission('supplierSyncList')) {
                    delete this.tempColumnConfig?.supplierInfo
                }
                if (!this.$hasPermission('detailInfo')) {
                    delete this.tempColumnConfig?.insDetailLink
                }
                return this.tableColumnConfig
            },
            set: function (val) {
                this.tableColumnConfig = val
            }
        },
        // tableColumns: function () {
        //     const columns = _.cloneDeep(insuranceColumns)
        //     if (!this.$hasPermission('supplierSyncList')) {
        //         delete columns?.supplierInfo
        //     }
        //     if (!this.$hasPermission('detailInfo')) {
        //         delete columns?.insDetailLink
        //     }
        //     return columns
        // },
        newToolKeys: function () {
            const keys = []
            // 2022-10-20:開放Admin可以使用客服服務後需依request的manCode是否和登入帳號相符去做限制
            if (this.$hasPermission('downloadExcel')) keys.push('excelEditing')
            keys.push('reminder')
            return keys
        }
    },
    watch: {
        filterLog: {
            handler(val) {
                const config = _.cloneDeep(insuranceColumns)
                if (!val.productFilter?.value) {
                    this.tableColumnConfig = _.omit(config, ['productFilter'])
                } else {
                    this.tableColumnConfig = config
                }
            },
            deep: true
        },
        tableShowData: {
            handler: function () {
                this.$reloadTips()
            },
            deep: true,
            immediate: true
        },
        'tableColumnConfig.propertyDueDate': {
            handler(val) {
                if (val !== undefined) {
                    this.$reloadTips()
                    this.isShowDueDateTips = true
                } else {
                    this.tableColumnConfig = insuranceColumns
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            isShowDueDateTips: false,
            isShowReminderSettingModal: false,
            isShowExcelEditingModal: false,
            sortValue: { sort: 'desc', sortBy: 'receiveDate' },
            tableColumnConfig: _.cloneDeep(insuranceColumns)
        }
    }
    // mounted() {
    //     let table = this.$refs?.table
    //     this.$watch(
    //         function () {
    //             return table.columnConfigValue.propertyDueDate
    //         },
    //         function (newVal) {
    //             console.log(newVal)
    //             this.$reloadTips()
    //             if (newVal.isShow === false) {
    //                 this.isShowDueDateTips = false
    //             } else {
    //                 this.isShowDueDateTips = true
    //             }
    //         },
    //         {
    //             deep: true,
    //             immediate: true
    //         }
    //     )
    // }
}
</script>

<style lang="scss" scoped>
:deep(.ins-detail-link) {
    position: relative;
    > .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

:deep(.supplier-info) {
    > .supplier-info-icon {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }
}

:deep(.has-event) {
    &:hover {
        color: $third-blue;
    }
    position: relative;
    cursor: pointer;
    &.notice-icon {
        width: 30px;
        height: 30px;
    }

    .notice-count {
        text-align: center;
        position: absolute;
        right: -10px;
        top: -5px;
        width: 17px;
        height: 18px;
        border-radius: 100%;
        background-color: $primary-red;
        color: $primary-white;
        font-weight: bold;
        transform: scale(0.9);
        font-size: 12px;
        line-height: 17px;
        transform: scale(0.8);
        padding-left: 1px;
        @media screen and (max-width: 576px) {
            text-align: center;
            right: -10px;
            top: -8px;
            font-size: 14px;
            width: 19px;
            height: 20px;
            line-height: 19px;
            transform: scale(0.8);
        }
    }
}

.customer {
    display: flex;
    text-decoration: underline;
    color: $primary-blue;
    cursor: pointer;
    &.no-data {
        color: $primary-black;
        cursor: default;
        text-decoration: none;
    }
}

.table-wrapper {
    :deep(.property-due-status) {
        align-items: flex-start;
        position: relative;
        .expire {
            position: absolute;
            top: 0;
            left: -20px;
            height: 40px;
            width: 16px;
        }
    }
    :deep(.header) {
        .info-icon {
            margin-left: 3px;
            width: 14px;
            height: 14px;
        }
        > .property-due-status {
            background-color: red;
        }
    }
    @media screen and (max-width: 576px) {
        :deep(.card .row-container .row.body) {
            justify-content: flex-end;
            .property-due-status {
                margin-right: auto;
                &.cell {
                    max-width: 0;
                    justify-content: flex-end;
                    &::before {
                        display: none;
                    }
                    .expire {
                        top: -30px;
                        left: -15px;
                    }
                }
            }

            .ins-detail-link {
                &.cell {
                    max-width: fit-content;
                    justify-content: flex-end;
                    &::before {
                        display: none;
                    }
                }
            }

            .supplier {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }
            }
            .supplier-info {
                &.cell {
                    max-width: fit-content;
                    &::before {
                        display: none;
                    }
                }
            }
            .notice {
                &.cell {
                    max-width: fit-content;
                    &::before {
                        display: none;
                    }
                }
            }
            .ins-status {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }
            }
        }
        .mobile-button {
            margin-left: 5px;
            background-color: transparent;
            box-shadow: 0 0 0 1px $primary-black inset;
        }
        :deep(.small-button) {
            .button-icon {
                height: 20px;
                width: 20px;
                margin-right: 3px;
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}

:deep(p) {
    display: flex;
    color: $secondary-grey;
    font-size: 12px;
    margin: 0;
    padding: 0;
    span {
        display: inline-block;
    }
}

:deep(.table-container) {
    @media screen and (max-width: 576px) {
        &.card {
            .table {
                .mobile-header {
                    display: block;
                }
            }
        }
    }
}
</style>
